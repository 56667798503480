import TypesetText from '~/editor/typeset-text/typeset-text'
import { chapter } from '~/services/current-document/chapter'
import { serializeCharStyles, serializeStyles } from '~/services/database/text-styles'
import { reportError } from '~/services/errors'
import { supabase } from '~/services/supabase'
import { Tables, TablesInsert } from '~/types/supabase'

type ChapterId = Tables<'chapters'>['id']

const getTypesetTexts = async (chapterId: ChapterId) => {
  const { data, error } = await supabase.rpc('get_typeset_texts', { chapter_id_param: chapterId })
  if (error) {
    reportError(error)
  }
  const typesetTexts = data ?? []
  return typesetTexts
}

const insertTypesetTexts = async (typesetTexts: TypesetText[]) => {
  const currentChapterId = chapter()?.id
  if(!currentChapterId){
    throw new Error('chapterId is undefined')
  }
  const rows: TablesInsert<'typeset_texts'>[] = typesetTexts.map(entry => {
    return {
      created_at: entry.date,
      chapter_id: currentChapterId,
      entry_id: entry.id,
      bounding_box: JSON.stringify(entry.boundingBox),
      text: entry.text,
      page_id: entry.page.id,
      style: serializeStyles({
        name: entry.styles.path,
        textStyle: entry.styles
      }),
      char_styles: serializeCharStyles(entry.charStyles),
      translation_id: entry.translationId
    }
  })
  const { data, error } = await supabase
    .from('typeset_texts')
    .insert(rows)
  if (error) {
    reportError(error)
  }
  typesetTexts = data ?? []
  return typesetTexts
}

const deleteTypesetTexts = async (ids: TypesetText['id'][]) => {
  const { data, error } = await supabase
    .from('typeset_texts')
    .update({
      deleted: true
    })
    .eq('entry_id', ids)
  if (error) {
    reportError(error)
  }
  return data
}

const resetTypesetTexts = async (chapterId: ChapterId) => {
  const { data, error } = await supabase
    .from('typeset_texts')
    .update({
      deleted: true
    })
    .eq('chapter_id', chapterId)
  if (error) {
    reportError(error)
  }
  return data
}

const restoreTypesetTexts = async (ids: TypesetText['id'][]) => {
  const { data, error } = await supabase
    .from('typeset_texts')
    .update({
      deleted: false
    })
    .eq('entry_id', ids)
  if (error) {
    reportError(error)
  }
  return data
}

export {
  getTypesetTexts,
  insertTypesetTexts,
  deleteTypesetTexts,
  restoreTypesetTexts,
  resetTypesetTexts
}